<template lang="">
  <div class="order-item">
    <div class="container">
      <div class="item">
        <span class="label">{{
          orderInfo && orderInfo.capitalPlatformCode == "LHYH"
            ? "应付融资余额"
            : "应付租金"
        }}</span>
        <div class="value">
          {{ trialResult.totalRends | amount | dash }}
        </div>
      </div>
      <div class="item">
        <span
          class="label"
          v-if="orderInfo && orderInfo.capitalPlatformCode == 'LHYH'"
        >
          <template v-if="orderInfo && orderInfo.capitalRepoFlag === 'Y'"
            >应付担保费补偿金</template
          >
          <template v-else>应付融担费</template>
        </span>
        <span class="label" v-else>应付费用</span>
        <div class="value">
          <span
            v-if="
              orderInfo &&
              orderInfo.capitalPlatformCode == 'LHYH' &&
              orderInfo.capitalRepoFlag !== 'Y'
            "
          >
            <template
              v-if="
                +trialResult.advancePenaltyAmt == 0 && +trialResult.feeAmt > 0
              "
            >
              {{ trialResult.capitalFeeAmt | amount }}
            </template>
            <span v-else>{{ trialResult.feeAmt | amount }}</span>
          </span>
          <span v-else>{{ trialResult.feeAmt | amount }}</span>
        </div>
      </div>
      <div class="item">
        <span class="label">{{
          orderInfo && orderInfo.capitalRepoFlag === "Y"
            ? "应付罚息"
            : "应付逾期违约金"
        }}</span>
        <span class="value">
          {{ trialResult.overdueInterestTotalAmt | columnAmount }}
        </span>
      </div>
      <div class="item">
        <span class="label"
          ><span v-if="orderInfo && orderInfo.capitalPlatformCode == 'LHYH'">
            <template v-if="orderInfo && orderInfo.capitalRepoFlag === 'Y'">
              应付回购违约金
            </template>
            <template v-else>应付担保费补偿金</template>
          </span>
          <span v-else>应付提前违约金</span></span
        >
        <div class="value">
          <span v-if="orderInfo && orderInfo.capitalRepoFlag === 'Y'">
            {{ trialResult.advancePenaltyAmt | amount }}
          </span>
          <span v-else>
            {{ trialResult.advancePenaltyAmt | amount }}
          </span>
        </div>
      </div>
      <template v-if="orderInfo && orderInfo.capitalPlatformCode != 'LHYH'">
        <div class="item">
          <span class="label">可抵扣金额</span>
          <span class="value">{{ trialResult.deductedAmt | amount }}</span>
        </div>
        <div
          class="item"
          v-if="['TSZL', 'TSZLT'].includes(orderInfo.capitalPlatformCode)"
        >
          <span class="label">名义价款</span>
          <span class="value">{{ trialResult.nominalPrice | amount }}</span>
        </div>
        <div class="item" v-else>
          <span class="label">应付第二资方金额</span>
          <span class="value">{{
            trialResult.secondPrincipalAmt | amount
          }}</span>
        </div>
      </template>
      <div class="item" v-if="show">
        <span class="label">提前还款总额</span>
        <span class="value"
          ><span v-if="trialResult.platformCalcRepayAdvanceAmt">
            {{ trialResult.platformCalcRepayAdvanceAmt | amount }}
          </span>
          <span v-else>
            {{ trialResult.repayAdvanceAmt | amount }}
          </span></span
        >
      </div>
      <div class="item">
        <span class="label">过期日期</span>
        <span class="value">{{ trialResult.orderEndTime }}</span>
      </div>
      <div class="item special">
        <span class="label">报价结果</span>
        <span class="value">{{ item.quotationResult | dash }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    orderInfo: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    trialResult() {
      let data = this.item.trialResult || {};
      data.totalRends = data.principalAmt + data.interestAmt;
      return data;
    },
  },
};
</script>
<style lang="less" scoped>
.order-item {
  .item {
    display: flex;
    overflow: hidden;
    line-height: 24px;
    &.special {
      width: 100% !important;
      .value {
        text-overflow: clip;
        white-space: wrap;
        overflow: initial;
        line-height: 1.2;
      }
    }
    span {
      display: block;
    }
    .label {
      flex: 0 0 80px;
      color: #666;
    }
    .value {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: #333;
    }
  }
  .container {
    background-color: #f5f7f9;
    padding: 12px;
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
