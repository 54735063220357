<template>
  <div class="custom">
    <div class="sticky">
      <NavBar title="结清试算" left-arrow @click-left="onClickLeft" />
      <Tabs v-model="activeName" @change="activeNameChange">
        <Tab title="结清试算" name="basic">
          <template #title
            ><img
              src="/images/icon-top-trial.png"
              alt=""
              srcset=""
            />结清试算</template
          >
        </Tab>
        <Tab title="试算记录" name="history">
          <template #title
            ><img
              src="/images/icon-top-trial-history.png"
              alt=""
              srcset=""
            />试算记录</template
          >
        </Tab>
      </Tabs>
    </div>
    <div class="content">
      <Collapse v-model="basicActiveNames" v-show="activeName == 'basic'">
        <CollapseItem title="试算信息" name="1" :is-link="false" disabled>
          <Form ref="form" validate-first>
            <Field
              readonly
              clickable
              required
              name="datetimePicker"
              :value="time"
              clearable
              label="提前结清日期"
              placeholder="点击选择时间"
              :rules="[{ required: true, timeValue }]"
              @click="showPicker = true"
              input-align="right"
            ></Field>
            <Button
              type="primary"
              plain
              icon="/images/icon-trial.png"
              class="submitBtn"
              hairline
              @click="trialTap"
              >立即试算</Button
            >
          </Form>
        </CollapseItem>
        <CollapseItem
          title="试算结果"
          v-if="trialResult.trialNo"
          name="2"
          :is-link="false"
          disabled
          class="label-top"
        >
          <div class="title">
            <div class="left">
              提前还款总额<Icon name="/images/icon-amount.png"></Icon>
            </div>
            <div class="right">
              <span v-if="trialResult.platformCalcRepayAdvanceAmt">
                {{ trialResult.platformCalcRepayAdvanceAmt | amount }}
              </span>
              <span v-else>
                {{ trialResult.repayAdvanceAmt | amount }}
              </span>
            </div>
          </div>
          <div class="container">
            <TrialHistory
              :orderInfo="orderInfo"
              :item="{ trialResult }"
            ></TrialHistory>
          </div>
        </CollapseItem>
      </Collapse>
      <Collapse v-model="orderActiveNames" v-show="activeName == 'history'">
        <List
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="100"
          :immediate-check="false"
        >
          <CollapseItem
            v-for="(item, index) in list"
            :key="index"
            :name="index"
            :is-link="false"
            class="history label-top"
            :value="orderActiveNames.includes(index) ? '收起结果' : '查看结果'"
          >
            <template #title>
              <div>{{ item.createBy }}</div>
              <div class="title-item">
                <span class="label">试算金额：</span>
                <span class="value"
                  ><span v-if="item.trialResult.platformCalcRepayAdvanceAmt">
                    {{ item.trialResult.platformCalcRepayAdvanceAmt | amount }}
                  </span>
                  <span v-else>
                    {{ item.trialResult.repayAdvanceAmt | amount }}
                  </span></span
                >
              </div>
              <div class="title-item">
                <span class="label">报价结果：</span
                >{{ item.quotationResult | dash }}
              </div>
              <div class="title-item">
                <span class="label">试算日期：</span
                >{{ item.createTime && item.createTime.substring(0, 10) }}
              </div>
            </template>
            <TrialHistory
              :orderInfo="orderInfo"
              show
              :item="item"
            ></TrialHistory
          ></CollapseItem>
        </List>
      </Collapse>
    </div>
    <Popup v-model="showPicker" position="bottom">
      <DatetimePicker
        type="date"
        title="选择年月日"
        @confirm="onConfirm"
        @cancel="showPicker = false"
        :min-date="minDate"
      ></DatetimePicker>
    </Popup>
  </div>
</template>

<script>
import {
  Tab,
  Tabs,
  NavBar,
  Collapse,
  CollapseItem,
  Button,
  Form,
  Field,
  DatetimePicker,
  Popup,
  Icon,
  Toast,
  List,
} from "vant";
import TrialHistory from "@/components/TrialHistory";
import moment from "moment";
import Api from "../../api";
export default {
  name: "Trial",
  components: {
    Tab,
    Tabs,
    NavBar,
    Collapse,
    CollapseItem,
    TrialHistory,
    List,
    Button,
    Form,
    Field,
    DatetimePicker,
    Popup,
    Icon,
  },
  data() {
    return {
      activeName: "basic",
      basicActiveNames: ["1", "2"],
      list: [],
      time: "",
      timeValue: /^\s*$/,
      showPicker: false,
      firstname: [],
      loading: false,
      finished: false,
      orderInfo: {},
      minDate: new Date(),
      trialResult: {},
      pageNo: 1,
      pageSize: 10,
    };
  },
  created() {
    this.projectCode = this.$route.query.projectCode;
    if (this.projectCode) {
      this.init();
    }
  },
  computed: {
    orderActiveNames: {
      get() {
        return this.firstname;
      },
      set(v) {
        this.firstname = v;
      },
    },
  },
  mounted() {
    // this.loadData();
  },
  methods: {
    init() {
      Api.findByBizNo(this.projectCode).then((res) => {
        this.orderInfo = res.data || {};
      });
    },
    onLoad() {
      // if (this.loading) {
      //   return;
      // }
      this.pageNo++;
      this.loadData();
    },
    activeNameChange(v) {
      if (v == "history") {
        this.pageNo = 1;
        this.loadData();
      }
    },
    async loadData() {
      const toast = Toast.loading({
        message: "请求中...",
        duration: 0,
        forbidClick: true,
      });
      const { pageNo, pageSize } = this;
      try {
        const res = await Api.repayFindPage({
          bizNo: this.projectCode,
          pageNo,
          pageSize,
        });
        this.loading = false;
        const { data } = res;
        if (data.list.length < pageSize) {
          this.finished = true;
        }
        if (pageNo == 1) {
          this.list = data.list.map((item) => ({
            ...item,
            trialResult: JSON.parse(item.trialResult),
          }));
        } else {
          this.list = [
            ...this.list,
            ...data.list.map((item) => ({
              ...item,
              trialResult: JSON.parse(item.trialResult),
            })),
          ];
        }
        toast.close();
      } catch (error) {
        this.finished = true;
        Toast.fail(error);
        toast.close();
      }
    },
    onFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    onSubmit() {
      console.log("1 :>> ", 1);
    },
    onConfirm(errorInfo) {
      this.time = moment(errorInfo).format("YYYY-MM-DD");
      this.showPicker = false;
      console.log("failed", errorInfo);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    clear() {
      this.time = "";
    },
    async trialTap() {
      await this.$refs.form.validate();
      if (!this.time) return Toast("请选择结清时间");
      const toast = Toast.loading({
        message: "请求中...",
        duration: 0,
        forbidClick: true,
      });
      let { capitalPlatformCode, bizNo, capitalIouCode, memberNo } =
        this.orderInfo;
      let data = {
        platform: capitalPlatformCode,
        bizNo,
        capitalIouNo: capitalIouCode,
        customerNo: memberNo,
        planRepayDate: this.time,
        repayType: "ADVANCE_SETTLE", // TODO: 这个地方字段有问题
      };
      try {
        await Api.repayCalculate(data).then((res) => {
          this.trialResult = res.data || {};
          this.trialResult.totalRends =
            this.trialResult.principalAmt + this.trialResult.interestAmt;
        });
        toast.clear();
      } catch (error) {
        toast.clear();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.history {
  position: relative;
  /deep/.van-cell__value {
    flex: 0 0 70px;
    position: absolute;
    top: 10px;
    right: 16px;
  }
}
/deep/ .van-nav-bar__content {
  .van-icon,
  .van-nav-bar__title {
    color: #fff;
  }
}
.sticky {
  padding-bottom: 50px;
  background: linear-gradient(
    180deg,
    #59c47e 0%,
    rgba(89, 196, 126, 0.6) 44%,
    rgba(245, 247, 249, 0.75) 100%
  );
}
.content {
  padding: 12px;
  font-size: 14px;
  margin-top: -50px;
}
.van-nav-bar {
  background: none;
}
/deep/ .van-tabs__wrap {
  height: 60px;
  .van-tabs__nav {
    background: none;
    .van-tab__text {
      display: flex;
      align-items: center;
      color: #fff !important;
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }
}
.van-collapse-item {
  border-radius: 4px;
  margin-bottom: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 4px 0px rgba(0, 6, 42, 0.08);
  /deep/ .van-cell__title {
    color: #323233 !important;
    font-weight: bold;
  }
  &.history {
    /deep/ .van-cell__value {
      color: @green;
    }
  }
  .submitBtn {
    width: 100%;
    margin-top: 16px;
  }
  &.label-top {
    /deep/ .van-collapse-item__content {
      display: flex;
      flex-wrap: wrap;
      .title {
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        .left {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .van-icon {
            margin-left: 5px;
          }
        }
        .right {
          color: @green;
        }
      }
      .container {
        background-color: #f5f7f9;
        display: flex;
        flex-wrap: wrap;
      }
      .item {
        width: 50%;
        flex-direction: column;
        margin-top: 5px;
        .label {
          flex: 1;
        }
        .value {
          font-weight: bold;
        }
      }
    }
  }
  .item {
    display: flex;
    overflow: hidden;
    line-height: 24px;
    span {
      display: block;
    }
    .label {
      flex: 0 0 80px;
      color: #666;
      &.large {
        flex: 0 0 100px;
      }
    }
    .value {
      flex: 1;
      color: #333;
    }
  }
}
/deep/.van-cell__title {
  .title-item {
    .label {
      color: #666;
      font-weight: normal;
    }
  }
}
</style>
